<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>姓名：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.name" placeholder="退款学生姓名"></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>身份证号：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.id_card" placeholder="退款学生身份证号"></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne">
                <el-button type="primary" @click="openExport">导出</el-button>
            </div>
        </div>
        <div class="baseTable" v-loading="tableLoading">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                         :public-table-configure="{rowKey:'current_transaction_id'}">
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="id_card" label="身份证号"></el-table-column>
                <el-table-column prop="refund_reason" label="退款原因"></el-table-column>
                <el-table-column prop="refund_time" label="申请时间" width="110"></el-table-column>
                <el-table-column prop="current_order_no" label="商户单号"></el-table-column>
                <el-table-column prop="current_transaction_id" label="支付单号"></el-table-column>
                <el-table-column prop="current_pay_time" label="支付时间" width="110"></el-table-column>
                <el-table-column prop="current_price" label="缴费金额"></el-table-column>
                <el-table-column prop="refund_desc" label="审核备注"></el-table-column>
                <el-table-column prop="current_refund_status" label="失败原因"></el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <template v-if="scope.row.operateShow">
                            <el-link class="tableButton" type="primary" @click.prevent="openRefund(scope.row)">
                                退款
                            </el-link>
                        </template>
                    </template>
                </el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
    </div>
</template>

<script>

import publicPage from '../../components/publicPage.vue'

import publicTable from '../../components/publicTable.vue'

import {businessGet, businessPost} from '../../api/business.js'

import {utilsExportExcel} from "../../utils/utils.js";

export default {
    name: "paySignUpRefundRun",
    components: {
        publicPage,
        publicTable
    },
    data() {
        return {
            searchForm: {
                name: '',
                id_card: '',
            },
            searchHeight: 0,
            tableLoading: false,
            tableData: [],
            total: 0,
            refundNum: 0,
            nowNum: 0,
        }
    },
    created() {
        this.$nextTick(() => {
            let baseSearchDom = document.querySelector('.baseSearch');
            let baseOperateDom = document.querySelector('.baseOperate');
            let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
            this.searchHeight = searchHeight;
            this.search();
        })
    },
    methods: {
        openExport() {
            let url = '/admin/v1/sign_up/archives?is_export=1&module=2&admission_status=7';
            for (let k in this.searchForm) {
                if (this.searchForm[k]) {
                    url = url + '&' + k + '=' + this.searchForm[k];
                }
            }
            utilsExportExcel(url)
        },
        openRefund(val) {
            let that = this;
            this.$confirm('此操作将为 ' + val.name + ' 进行报名费退款, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                startSubmit(val)

                // let promiseList = [];
                // val.order_list.forEach(item => {
                //     promiseList.push(moneyRefund(item))
                // })
                // Promise.all(promiseList).then(function (values) {
                //     console.log(values);
                //     let allSuccess = true;
                //     values.forEach(item=>{
                //         if(item.status != 200){
                //             allSuccess = false;
                //         }
                //     })
                //     if(allSuccess){
                //         that.$message({
                //             showClose: true,
                //             message: values[0].message,
                //             type: 'success'
                //         });
                //     }
                //     else{
                //         that.$message({
                //             showClose: true,
                //             message: '退款异常，请在列表中查看失败原因',
                //             type: 'warning'
                //         });
                //     }
                //     that.getTableData();
                // });
            }).catch(() => {

            })

            async function startSubmit(val) {
                for (let i = 0; i < val.order_list.length; i++) {
                    let item = val.order_list[i];
                    let a = await moneyRefund(item)
                    if (a == 200) {
                        if (i == val.order_list.length - 1) {
                            that.$message({
                                showClose: true,
                                message: a.message,
                                type: 'success'
                            });
                        }
                    }
                    else {
                        that.$message({
                            showClose: true,
                            message: a.message,
                            type: 'warning'
                        });
                        break
                    }
                }
                that.getTableData();
            }

            function moneyRefund(val) {
                return new Promise(resolve => {
                    let url = '/admin/v1/sign/refund';
                    let data = {
                        transaction_id: val.transaction_id,
                        refund_price: val.price,
                    }
                    businessPost(url, data).then(res => {
                        resolve(res.data)
                    })
                })
            }
        },
        applyPass(val) {
            this.$confirm('此操作将同意 ' + val.name + ' 的退款申请, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                showInput: true,
                inputPlaceholder: '备注：',
                type: 'warning',
            }).then(({value}) => {
                let url = '/admin/v1/sign_up/archives/status';
                let data = {
                    archives_id: val.id,
                    admission_status: 7,
                    refund_desc: value
                };
                businessPost(url, data).then(res => {
                    if (res.data.status == 200) {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        this.getTableData();
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }).catch(() => {

            })
        },
        cleanAll() {
            this.searchForm.name = '';
            this.searchForm.id_card = '';
            this.search();
        },
        search() {
            this.$refs.publicPageUserStudent.currentPage = 1;
            this.getTableData();
        },
        getTableData() {
            let url = '/admin/v1/sign_up/archives?module=2&admission_status=7&page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
            for (let k in this.searchForm) {
                if (this.searchForm[k]) {
                    url = url + '&' + k + '=' + this.searchForm[k];
                }
            }
            this.tableLoading = true;
            businessGet(url).then(res => {
                this.tableLoading = false;
                if (res.data.status == 200) {
                    this.total = res.data.data.count;
                    let tableData = res.data.data.list;
                    tableData.forEach(item => {
                        let newItem = JSON.parse(JSON.stringify(item))
                        item.order_list.forEach((son, sonIndex) => {
                            if (sonIndex == 0) {
                                item.children = [];
                                item.current_price = son.price;
                                item.current_transaction_id = son.transaction_id;
                                item.current_order_no = son.order_no;
                                item.current_pay_time = son.pay_time;
                                item.current_refund_status = son.refund_status;
                                item.operateShow = true;
                            }
                            else {
                                newItem.current_price = son.price;
                                newItem.current_transaction_id = son.transaction_id;
                                newItem.current_order_no = son.order_no;
                                newItem.current_pay_time = son.pay_time;
                                newItem.current_refund_status = son.refund_status;
                                newItem.operateShow = false;
                                item.children.push(newItem)
                            }
                        })
                    })
                    this.tableData = tableData;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
    }
}
</script>

<style scoped>

@import "../../assets/css/base.css";

.container {
    width: 100%;
    height: 600px;
    box-sizing: border-box;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

</style>
